/* ----------------------------------------------------------------------------
    Colors
----------------------------------------------------------------------------- */
$blue:    #316ce8 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #fb4f16 !default;
$orange:  #fd7e14 !default;
$yellow:  #f6c23e !default;
$green:   #1cc88a !default;
$teal:    #36b9cc !default;
$cyan:    #17a2b8 !default;
$gray-600: #6c757d !default;

/* ----------------------------------------------------------------------------
    Custom Colors
----------------------------------------------------------------------------- */
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;

/* ----------------------------------------------------------------------------
    Theme Colors
----------------------------------------------------------------------------- */
$theme-primary:       #4176aa !default;
$theme-secondary:     #1f69a1 !default;
$headline-primary:    $theme-primary !default;
$headline-secondary:  $theme-secondary !default;
$theme-nav-primary:   $theme-primary !default;
$theme-nav-secondary: $theme-secondary !default;
$link-color:          #1982d1 !default;

/* ----------------------------------------------------------------------------
    CTA buttons
----------------------------------------------------------------------------- */
$cta-red:      #f44336 !default;
$cta-orange:   #FF6633 !default;
$cta-green:    #5CB865 !default;
$cta-blue:     #0099ff !default;
$cta-yellow:   #ffc107 !default;
$cta-dark:     #555555 !default;

/* ----------------------------------------------------------------------------
    text color overrides
----------------------------------------------------------------------------- */
.text-theme-primary {
  color: $theme-primary !important;
}
.text-theme-secondary {
  color: $theme-secondary !important;
}
.text-headline-primary {
  color: $headline-primary !important;
}
.text-headline-secondary {
  color: $headline-secondary !important;
}
.border-theme-secondary {
  border-color: $theme-secondary !important;
}

