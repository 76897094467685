/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #777;
  border-top: 1px solid #e4e4e4;
  font-size: 0.9rem;
  h3,
  h4 {
    color: $theme-secondary;
  }
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #777;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
  #trustwaveSealImage {
    margin: 0 10px 30px 0;
  }
}

/* --------- asc styles --------- */
#header {
  background: rgba(255, 255, 255, 0.8);
  a.logo {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    width: 80%;
    max-width: 650px;
    img {
      margin-right: 10px;
      width: auto;
      height: auto;
      max-width: 40px;
    }
    span.big,
    span.small {
      font-size: 1.4rem;
      color: #555;
      line-height: 1.3;
    }
    span.big {
      font-weight: 600;
      font-family: var(--font-family);
      color: $theme-primary;
    }
    span.small {
      font-size: 14px;
      font-family: var(--font-family);
      font-weight: 600;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: $theme-primary;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten($theme-primary, 7.5%);
      }
    }
    a.help-center {
      margin: 0 10px 0 0;
      font-weight: normal;
      color: #555;
      border: 0;
      text-decoration: none;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        &:before {
          color: $theme-primary;
        }
      }
    }
  }
}
#banner-wrap {
  background: #fff url(../images/banner-bg.jpg) repeat 0 0 / cover;
  h1 {
    color: #fff;
    text-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
  }
  .details {
    max-width: 750px;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .special {
    color: #fb770b !important;
  }
  .pitch {
    h2 a {
      color: #094776;
      font-weight: 700;
    }
    h4 {
      padding: 8px 15px;
      background: #4e9ff2;
      color: #fff;
    }
  }
  &.county {
    #banner {
      .seal {
        position: relative;
        left: auto;
        top: auto;
      }
    }
  }
}
.card {
  &.light-blue {
    background-color: #f7fbfe;
    border-color: #c5e2f0;
  }
}
.cta-wrap {
  background-color: #094776;
  color: #fff;
}

// custom dropdown CTA styles
.cta-dropdown {
  width: 220px;
  vertical-align: middle;
  display: inline-block;
  span.dropdown {
    padding: 14px;
    padding-bottom: 12px;
    color: #fff;
    font-weight: 400;
    background: $cta-red;
    background-image: linear-gradient(lighten($cta-red, 8), darken($cta-red, 5));
    border: 0;
    height: auto;
    line-height: 1;
    font-size: 21px;
    text-align: center;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 45px;
      width: 220px !important;
      li a {
        color: #333 !important;
        font-size: 0.9rem;
      }
    }
    &:after {
      display: none;
    }
    &:hover {
      background: darken($cta-red, 7) !important;
    }
  }
  &.small {
    span.dropdown {
      padding: 14px 0 11px 0;
      font-size: 18px;
      & + .drop {
        top: 42px;
      }
    }
  }
}

/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header a.logo {
    img {
      max-width: 60px;
    }
    span.big {
      font-size: 1.7rem;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header a.logo {
    img {
      max-width: 94px;
      margin-right: 15px;
    }
    span.big {
      margin-top: 5px;
      font-size: 2em;
    }
  }
  #banner-wrap {
    background: #fff url(../images/banner-bg.jpg) repeat 0 0 / contain;
  }
}
// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header-wrap {
    border-top: 3px solid $theme-primary;
    #header a.logo {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 70%;
    }
  }
  #banner-wrap {
    #banner {
      background: url(../images/banner-person.png) no-repeat 20px bottom;
      position: relative;
      .seal {
        position: absolute;
        top: 30px;
        left: 30px;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #banner-wrap {
    #banner {
      background: url(../images/banner-person.png) no-repeat 80px bottom;
      position: relative;
      .seal {
        top: 20px;
        left: 20px;
      }
    }
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
